import WrapperTwoLayout from '@/components/Layouts/WrapperTwoLayout';
import { HomeTopSectionL, HomeTopSectionR } from './components';
import { GetPostsQuery } from '@/generated/graphql';
import format from '@/utils/format';

export interface TopSectionProps {
  data?: GetPostsQuery;
  isLoading?: boolean;
}

const HomeTopSection = ({ data }: TopSectionProps) => {
  const dataPost = format.formatDataPost(data?.posts);
  return (
    <WrapperTwoLayout
      leftColumn={<HomeTopSectionL data={dataPost?.slice(0, 4)} />}
      rightColumn={<HomeTopSectionR data={dataPost?.slice(4, 8)} />}
    />
  );
};

export default HomeTopSection;
