import { ReactNode } from 'react';

export type IWrapperTwoLayoutProps = {
  leftColumn: ReactNode;
  rightColumn: ReactNode;
};

const WrapperTwoLayout = ({
  leftColumn,
  rightColumn,
}: IWrapperTwoLayoutProps) => {
  return (
    <div
      className="flex gap-2 flex-col lg:flex-row"
      style={{
        animation: 'animate-fadein 0.5s',
      }}
    >
      <div className="w-full lg:w-2/3 flex flex-col">{leftColumn}</div>
      <div className="w-full lg:w-1/3 flex flex-col">{rightColumn}</div>
    </div>
  );
};

export default WrapperTwoLayout;
